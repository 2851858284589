<template>
  <div
    :class="{ 'title-reduce-width': isSmCustomTitle }"
    class="question-title title-reduce-width"
  >
    <h1 class="title" :class="[subtitle ? 'title--with-subtitle': '', titleColorClass]">
      <slot name="title">
        {{ title }}
      </slot>
      <span
        v-if="tooltip && !tooltipMobileOnly"
        v-tooltip.left-start="{ content: tooltip, classes: 'title-tooltip' }"
        class="title-tooltip__outer desktop-only"
      >
        <img src="@/assets/icons/more-info.png" alt="?">
      </span>
      <span
        v-if="tooltip"
        class="up-to-tablet title-tooltip"
        @click="showModal = true"
      >
        <img src="@/assets/icons/more-info.png" alt="?">
      </span>
    </h1>
    <h2 v-if="subtitle" class="subtitle">
      <slot name="subtitle">
        <span v-html="subtitle" />  <!-- eslint-disable-line vue/no-v-html -->
      </slot>
    </h2>
    <div v-if="!subtitle" class="title-spacer" />
    <Modal v-if="showModal" class="title-modal" @close="showModal = false">
      <div slot="body" v-html="tooltip" /> <!-- eslint-disable-line vue/no-v-html -->
    </Modal>
  </div>
</template>

<script>
import Modal from '../shared/Modal'
export default {
  name: 'QuestionTitle',
  components: {
    Modal
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    titleColorClass: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    tooltip: {
      type: String,
      default: ''
    },
    tooltipMobileOnly: {
      type: Boolean,
      default: false
    },
    isSmCustomTitle: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showModal: false
    }
  }
}
</script>
<style lang="scss">
.title-modal {
  .tooltip-title {
    font-size: 20px;
    font-weight: bold;
  }
  p {
    color: #011240;
  }
}

.title-tooltip {
  .tooltip-inner {
    border-radius: 8px;
    width: 477px;
    color: #011240;
    .tooltip-title {
      font-size: 20px;
      font-weight: bold;
    }
  }
  &__outer {
    margin-left: 10px;
  }
  img {
    width: 17px;
    @media (min-width: $desktop-breakpoint-min) {
      width: 24px;
    }
  }
}
</style>

<style scoped lang="scss">
.question-title {
  .title {
    color: $bionic-blue;
    font-weight: normal;
    text-align: left;
    font-size: 20px;
    line-height: 26px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: $spacing-32;
    letter-spacing: -0.5px;

    @media (min-width: $tablet-min) {
      letter-spacing: -0.7px;
      line-height: 36px;
      font-size: 28px;
      text-align: center;
      max-width: 340px;
      margin-bottom: $spacing-24;
    }

    @media (min-width: $tablet-max) {
      max-width: 575px;
    }

    &--with-subtitle {
      margin-bottom: 10px;

      @media (min-width: $tablet-min) {
        margin-bottom: $spacing-18;
      }
      @media (min-width: $tablet-max) {
        margin-bottom: 10px;
      }
    }
  }

  .title-reduce-width {
    .title,
    .subtitle {
      @media (max-width: 719px) {
        max-width: unset;
        margin-left: 0;
        margin-right: 0;
        text-align: center;
      }

      @media (max-width: $tablet-min) {
        text-align: left;
      }
    }
  }

  .subtitle {
    color: $color-grey-2;
    opacity: 0.8;
    letter-spacing: -0.64px;
    font-weight: 400;
    margin: $spacing-0 auto 26px;
    font-size: 15px;
    line-height: 16px;
    text-align: left;

    @media (min-width: $tablet-min) {
      font-size: 16px;
      max-width: 286px;
      text-align: center;
      line-height: 18px;
    }

    @media (min-width: 720px) {
      max-width: 525px;
    }
  }
}

.title-spacer {
  @media (min-width: $screen-md) {
    height: 32px;
  }
}
span.desktop-only {
  @media (min-width: $mobile-breakpoint-max) {
    display: inline;
  }
  @media (max-width: $mobile-breakpoint-max) {
    display: none;
  }
}
span.mobile-only {
  @media (min-width: $mobile-breakpoint-max) {
    display: none;
  }
  @media (max-width: $mobile-breakpoint-max) {
    display: inline;
  }
}
.up-to-tablet {
  display: inline;

  @media (min-width: 1400px) {
    display: none;
  }
}

.modal-header {
  border-bottom: none;
}
</style>
