<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <div class="modal-header">
          <slot name="header">
            <img src="@/assets/icons/x.svg" alt="close" @click="$emit('close')">
          </slot>
        </div>

        <div class="modal-body">
          <slot name="body">
            default body
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
}

</script>

<style lang="scss" scoped>

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  vertical-align: middle;
  padding: 100px 20px 0 20px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.modal-container {
  margin: 0px auto;
  border-radius: 16px;
  background-color: #FFFFFF;
  box-shadow: 0 0 8px 0 rgba(0,0,0,0.2);
  line-height: 18px;
  font-size: 13px;
  display: flex;
  flex-direction: row-reverse;
  text-align: left;
}

.modal-header {
  margin-top: 0;
  padding: 8px 8px 0 0;
  border-bottom: none;

  img {
    cursor: pointer;
  }
}

.modal-body {
  padding: 16px 0 5px 20px;
}

</style>
